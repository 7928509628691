import styles from "./Login1.module.css";

const Login1 = () => {
  return (
    <div className={styles.login1}>
      <img className={styles.vectorIcon} alt="" src="/vector.svg" />
      <img className={styles.groupIcon} alt="" src="/group.svg" />
      <div className={styles.group}>
        <div className={styles.allYourRoutes}>
          All your routes and directions in one place.
        </div>
      </div>
      <div className={styles.group1}>
        <b className={styles.allYourRoutes}>Log in</b>
      </div>
      <div className={styles.group2}>
        <div className={styles.allYourRoutes}>Username</div>
      </div>
      <img className={styles.groupIcon1} alt="" src="/group1.svg" />
      <div className={styles.group3}>
        <div className={styles.allYourRoutes}>Password</div>
      </div>
      <img className={styles.groupIcon2} alt="" src="/group1.svg" />
      <button className={styles.loginButton}>
        <img className={styles.vectorIcon1} alt="" src="/vector1.svg" />
        <div className={styles.logIn1}>Log in</div>
      </button>
      <div className={styles.maskGroup}>
        <div className={styles.group4}>
          <b className={styles.allYourRoutes}>Navigate with Ease</b>
        </div>
      </div>
      <img className={styles.groupIcon3} alt="" src="/group2.svg" />
    </div>
  );
};

export default Login1;
